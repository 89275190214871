<template>
  <div
    id="navbar"
    class="navbar-container d-flex align-items-center w-100"
  >
    <b-navbar
      class="w-100 bg-transparent"
      toggleable="md"
    >
      <b-navbar-brand
        data-aos="fade-right"
      >
        <div class="brand-logo d-flex align-items-center justify-content-center">
          <b-img
            alt="logo"
            src="@/assets/images/simiicons/S.svg"
            style="max-width: 36px"
          />
        </div>
        <div class="brand-logo">
          <b-img
            alt="logo"
            src="@/assets/images/simiicons/Text.svg"
            style="max-width: 100px"
          />
        </div>
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse" />
      <b-collapse
        id="nav-collapse"
        is-nav
      >
        <!-- Right aligned nav items -->
        <b-navbar-nav
          class="ml-auto"
          data-aos="fade-left"
        >
          <b-nav-item
            active
            class="nav-item-style "
          >
            Home
          </b-nav-item>
          <b-nav-item
            class="nav-item-style"
          >
            About Us
          </b-nav-item>
          <b-nav-item
            class="nav-item-style"
          >
            Products
          </b-nav-item>
          <b-nav-item
            class="nav-item-style"
          >
            Clients
          </b-nav-item>
          <b-nav-item>
            <b-button
              pill
              variant="primary"
            >
              Contact Us
            </b-button>
          </b-nav-item>
          <b-nav-item>
            <div v-if="!$auth.loading">
              <b-button
                v-if="!$auth.isAuthenticated"
                pill
                variant="primary"
                @click="login"
              >
                Login
              </b-button>
            </div>
            <b-button
              v-if="$auth.isAuthenticated"
              pill
              variant="primary"
              @click="logout"
            >
              Logout
            </b-button>
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import {
  BNavbarNav, BNavbar, BCollapse, BNavbarBrand, BNavbarToggle, BNavItem, BImg, BButton,
} from 'bootstrap-vue'
import 'animate.css'

export default {
  components: {
    BNavbarNav,
    BNavbar,
    BCollapse,
    BNavbarBrand,
    BNavbarToggle,
    BNavItem,
    BImg,
    BButton,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {
      },
    },
  },
  methods: {
    login() {
      this.$auth.loginWithRedirect()
    },
    logout() {
      this.$auth.logout()
      this.$router.push({ path: '/' })
    },
  },
}
</script>
<style lang="scss">
#navbar {
  .nav-item-style {
    top: 62px;
    left: 1133px;
    width: 110px;
    height: 23px;
    color: #1F58B5;
    font-size: 14px;
    font-weight: bold;
    padding-top: 8px;
  }

  .nav-link {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  a:hover {
    background-color: rgb(34 41 47 / 3%) !important;
    border-radius: 20px !important;
  }

  .active.nav-link {
    background-color: rgb(34 41 47 / 3%) !important;
    border-radius: 20px !important;
  }
}

</style>
