<template>
  <section>
    <div
      class="comp-div-clients ourClients"
    >
      <span
        class="heading-class"
        data-aos="flip-right"
      >Our Corporate Clients</span>
      <p
        class="p-class"
        data-aos="zoom-in"
      >Donec euismod arcu vel neque volutpat, sed
        ullamcorper tortor blandit. Suspendisse potenti.
        Nunc at lacus neque.</p>
      <div class="p-2">
        <swiper
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="swiperOptions"
          class="swiper-multiple"
          data-aos="zoom-in-left"
        >
          <swiper-slide
            v-for="(data,index) in swiperData"
            :key="index"
          >
            <div class="h-5 w-5 d-flex justify-content-center align-items-center slider-div">
              <b-img
                :src="data.img"
                class="p-1"
              />
            </div>
          </swiper-slide>

          <div
            slot="pagination"
            class="swiper-pagination"
          />
        </swiper>
      </div>
    </div>
    <div class="footer-comp ourClients h-100">
      <span
        class="content-class-title h-20 "
        data-aos="flip-right"
      >A few words from our clients..</span>
      <div class="pb-5">
        <swiper
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="singleSwiperOptions"
          class="swiper-cube-effect"
          data-aos="zoom-in-left"
        >
          <swiper-slide
            v-for="(data,index) in swiperDataSingle"
            :key="index"
          >
            <div class="avatar-div-class">
              <b-avatar
                :src="data.img"
                class="avatar-class"
                size="45"
              />
            </div>
            <div class="w-30 d-flex justify-content-center align-items-center">
              <p class="content-class">
                {{ data.text }}
              </p>
            </div>
          </swiper-slide>
          <div
            slot="pagination"
            class="swiper-pagination swiper-pagination-white"
          />
        </swiper>
      </div>
    </div>
  </section>
</template>
<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import { BImg, BAvatar } from 'bootstrap-vue'
import 'swiper/css/swiper.css'
import 'animate.css'

export default {
  components: {
    Swiper,
    SwiperSlide,
    BAvatar,
    BImg,
  },
  data() {
    return {
      /* eslint-disable global-require */
      swiperData: [
        { img: require('@/assets/images/landPageImg/dashboardIcon.png') },
        { img: require('@/assets/images/landPageImg/dashboardIcon.png') },
        { img: require('@/assets/images/landPageImg/dashboardIcon.png') },
        { img: require('@/assets/images/landPageImg/dashboardIcon.png') },
        { img: require('@/assets/images/landPageImg/dashboardIcon.png') },
      ],
      swiperDataSingle: [
        {
          img: require('@/assets/images/avatars/1.png'),
          text: 'Donec euismod arcu vel neque volutpat, sed ullamcorper tortor blandit.Donec euismod arcu vel neque volutpat, sed ullamcorper tortor blandit',
        },
        {
          img: require('@/assets/images/avatars/2.png'),
          text: 'Donec euismod arcu vel neque volutpat, sed ullamcorper tortor blandit.Donec euismod arcu vel neque volutpat, sed ullamcorper tortor blandit',
        },
        {
          img: require('@/assets/images/avatars/3.png'),
          text: 'Donec euismod arcu vel neque volutpat, sed ullamcorper tortor blandit.Donec euismod arcu vel neque volutpat, sed ullamcorper tortor blandit',
        },
      ],
      swiperOptions: {
        slidesPerView: 6,
        spaceBetween: 30,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      },
      singleSwiperOptions: {
        slidesPerView: 1,
        spaceBetween: 30,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      },
    }
  },

}
</script>

<style lang="scss">
.ourClients {
  //.swiper-slide {
  //  width: 15% !important;
  //}
  p {
    text-align: center !important;
  }

  .swiper-container {
    .swiper-wrapper {
      display: flex !important;
      justify-content: center !important;
      padding: 5px;
    }

    .swiper-pagination-bullet-active {
      background-color: black !important;
    }

    .swiper-pagination-bullet {
      background-color: grey !important;
    }
  }

  .slider-div {
    background-color: white;
    //box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 4px -3px;
  }

  .heading-class {
    font-family: 'Red Hat Display', sans-serif;
    font-size: 2rem;
    font-weight: 900;
    height: 100px;
    letter-spacing: 0px;
    color: #262626;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .p-class {
    top: 1200px;
    font-size: 1rem;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Red Hat Display', sans-serif;
    color: #999999;
    opacity: 1;
  }

  .swiper-container-cube .swiper-cube-shadow {
    background-color: transparent !important;
  }

  .content-class {
    width: 50%;
    font-family: 'Red Hat Display', sans-serif;
    font-size: 1rem;
    font-weight: 600;
    height: 100px;
    letter-spacing: 0px;
    color: #262626;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .content-class-title {
    font-family: 'Red Hat Display', sans-serif;
    font-size: 1rem;
    font-weight: 600;
    height: 100px;
    letter-spacing: 0px;
    color: #262626;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .avatar-class {
    border: 8px solid slategray;
  }

  .b-avatar {
    width: 10% !important;
    height: 65% !important;
  }

  .avatar-div-class {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.comp-div-clients {
  background-color: #ffffff;
}

.footer-comp {
  background-color: #F8F8F8;
}
</style>
