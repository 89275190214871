<template>
  <section>
    <div class="w-100 d-flex justify-content-center align-items-center">
      <div class="contact-div">
        <div
          class="d-flex justify-content-center py-1 p-0 d-md-flex justify-content-md-between align-items-center  "
        >
          <div class="footer-div">
            Ready to get started ?
          </div>
          <div>
            <b-button
              pill
              variant="primary"
            >
              Contact Us
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <div
      id="footer"
      class="footer-div-class h-100 w-100 position-relative"
    >
      <b-row class="row-class pb-3">
        <b-col
          class="h-100 d-flex justify-content-center align-items-center"
          md="3"
          sm="6"
          xsm="6"
        >
          <div
            class="d-flex justify-content-center align-items-center p-5"
            data-aos="zoom-out"
          >
            <div class="h-100 ">
              <div class="d-flex align-items-center justify-content-center">
                <b-img
                  alt="logo"
                  src="@/assets/images/simiicons/S.svg"
                  style="max-width: 36px"
                />
              </div>
              <div class="d-flex align-items-center justify-content-center">
                <b-img
                  alt="logo"
                  src="@/assets/images/simiicons/Text.svg"
                  style="max-width: 100px"
                />
              </div>
            </div>
          </div>
        </b-col>
        <b-col
          md="2"
          sm="6"
          xsm="6"
        >
          <div
            class="h-100"
            data-aos="zoom-out"
          >
            <div class="footer-content-title h-20">
              Company
            </div>
            <div class="div-class">
              <b-link class="footer-content">
                Home
              </b-link>
            </div>
            <div class="div-class">
              <b-link class="footer-content">
                About Us
              </b-link>
            </div>
            <div class="div-class">
              <b-link class="footer-content">
                Products
              </b-link>
            </div>
            <div class="div-class">
              <b-link class="footer-content">
                Services
              </b-link>
            </div>
          </div>
        </b-col>
        <b-col
          md="3"
          sm="6"
          xsm="6"
        >
          <div
            class="d-flex flex-column justify-content-start align-items-start pb-3"
            data-aos="zoom-out"
            style="height: 140px !important;"
          >
            <div class="h-100 p-sm-2">
              <div class="footer-content-title h-20">
                Contact
              </div>
              <div class="footer-content">
                Level12, 31 Queens street, melborne 3000
              </div>
              <b-link class="footer-content">
                info@simiconnect.com
              </b-link>
            </div>
          </div>
        </b-col>
        <b-col
          md="3"
          sm="6"
          xsm="6"
        >
          <div
            class="h-100 d-flex flex-column justify-content-start align-items-start"
            data-aos="zoom-out"
            style="height: 100px !important;"
          >
            <div class="footer-content-title h-20">
              Social Media
            </div>
            <div class="d-flex ">
              <b-link class="footer-content px-1">
                <b-img
                  src="@/assets/images/landPageIcons/beIcon.svg"
                  width="14px"
                />
              </b-link>
              <b-link class="footer-content px-1">
                <b-img
                  src="@/assets/images/landPageIcons/icon.svg"
                  width="14px"
                />
              </b-link>
              <b-link class="footer-content px-1">
                <b-img
                  src="@/assets/images/landPageIcons/fbIcon.svg"
                  width="14px"
                />
              </b-link>
              <b-link class="footer-content px-1">
                <b-img
                  src="@/assets/images/landPageIcons/linkedInIcon.svg"
                  width="14px"
                />
              </b-link>
              <b-link class="footer-content px-1">
                <b-img
                  src="@/assets/images/landPageIcons/twiterIcon.svg"
                  width="14px"
                />
              </b-link>
              <b-link class="footer-content px-1">
                <b-img
                  src="@/assets/images/landPageIcons/deviceIcon.svg"
                  width="14px"
                />
              </b-link>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </section>
</template>
<script>
import {
  BRow, BCol, BImg, BLink, BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BImg,
    BLink,
    BButton,
  },
  data() {
    return {
      companyPages: ['Home', 'About Us', 'Products', 'Services'],
    }
  },
}
</script>
<style lang="scss">
#footer {
  a {
    color: white !important;
    text-decoration: none !important;
  }

  .footer-content-title {
    color: #FFFFFF;
    font-family: 'Red Hat Display', sans-serif;
    font-size: 1rem;
    font-weight: 600;
    letter-spacing: 0px;
  }

  .footer-content {
    color: #FFFFFF;
    font-family: 'Red Hat Display', sans-serif;
    font-size: 0.9rem;
    font-weight: 400;
    letter-spacing: 0px;
  }

  .div-class {
    padding-top: 5px;
  }

  .row-class {
    min-height: 300px;
    display: flex;
    justify-content: end;
    align-items: flex-end;
  }
}

.contact-div {
  background-color: white;
  border-radius: 10px;
  width: 60%;
  height: 80px;
  margin-top: -6px;
  z-index: 9;
  position: absolute;
  padding: 7px;
}

.footer-div-class {
  background-color: #001A44;
}

.footer-div {
  color: black;
  font-family: 'Red Hat Display', sans-serif;
  font-size: 1.5rem;
  font-weight: 900;
  letter-spacing: 0px;
}

</style>
